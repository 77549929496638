// import React, { useRef } from "react";
import React from "react";
import Head from "../components/head.js";

// import emailjs from "emailjs-com";

// import "./contact.module.css";
import * as contactStyles from "./contact.module.css";

import Layout from "../components/layout";

// const ContactPage = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs
//       .sendForm(
//         "service_5i22g37",
//         "template_as8su9z",
//         form.current,
//         "user_fmr67qGrQTxPgebeMxS1O"
//       )
//       .then(
//         (result) => {
//           console.log(result.text);
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//     e.target.reset();
//     alert("Message Sent!");
//   };

//   return (
//     <Layout>
//       <Head title="Contact" />
//       <div className={contactStyles.contactContainer}>
//         <h3>Contact</h3>
//         <p>
//           The form sends <b>artnow.world</b> your submission to be included on
//           the website. The website is free and made with / for the love of it.
//         </p>

//         <p>
//           Every aspect / field is optional / open to you, but things you might
//           consider including are:
//         </p>

//         <ul className={contactStyles.contactInfoList}>
//           <li>Name</li>
//           <li>Location (city / country / etc.)</li>
//           <li>Medium</li>
//           <li>Statement of any kind</li>
//           <li>Links to pictures / soundcloud / youtube video embed / etc.</li>
//           <li>How people can contact you</li>
//           <li>Social media links</li>
//           <li>Other links, etc.</li>
//         </ul>

//         <form ref={form} onSubmit={sendEmail}>
//           <label htmlFor="name">Name</label>
//           <input type="text" name="from_name" />
//           <label htmlFor="email">Email</label>
//           <input type="email" name="user_email" />
//           <label htmlFor="message">Message</label>
//           <textarea name="message" />
//           <p style={{ marginTop: 30, marginBottom: 10 }}>
//             Looking forward to seeing what you send!
//           </p>
//           <input type="submit" value="Send" />
//         </form>
//         <br />
//         {/* <p>Connect with artnow.world on Social Media as well!</p>
//         <h3>
//           <a href="https://facebook.com/artnow.world">Facebook</a>
//         </h3>
//         <h3>
//           <a href="https://instagram.com/artnow.world">Instagram</a>
//         </h3> */}
//       </div>
//     </Layout>
//   );
// };

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <div className={contactStyles.contactContainer}>
        <h3>Contact</h3>
        <p>
          If you would like your art to be listed on artnow.world, currently
          find a way to contact Benji Friedman.
        </p>
      </div>
    </Layout>
  );
};

export default ContactPage;
